export const situacionFinanciera = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.1_ESTADO_DE_SITUACION_FINANCIERA.pdf"
                    }
                ]

            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.1_ESTADO_DE_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.1_ESTADO_DE_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.1_ESTADO_DE_SITUACION_FINANCIERA.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.1_ESTADO_DE_SITUACIÓN_FINANCIERA.xlsx",
                        "pdf":   "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.1_ESTADO_DE_SITUACION_FINANCIERA.pdf"
                    }
                ]

            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.1_ESTADO_DE_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/1_INFORMACION_CONTABLE/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.1_ESTADO_DE_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.1_ESTADO_DE_ SITUACION_ FINANCIERA.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2022",
        "trimestre": [
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2021",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2021/"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2020",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2020/"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2019",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/ESTADO%20DE%20SITUACION%20FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.1_ESTADO_DE_SITUACION_FINANCIERA_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.1_ESTADO%20DE%20SITUACION_FINANCIERA_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.1_ESTADO_DE_SITUACION_FINANCIERA_2019.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2018",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_1_ESTADO_DE_SITUACION_FINANCIERA_2018/ESTADO%20DE%20SITUACI_N%20FINANCIERA%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_1_ESTADO_DE_SITUACION_FINANCIERA_2018/ESTADO%20DE%20SITUACI_N%20FINANCIERA%202do.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_1_ESTADO_DE_SITUACION_FINANCIERA_2018/ESTADO%20DE%20SITUACI_N%20FINANCIERA%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_1_ESTADO_DE_SITUACION_FINANCIERA_2018/ESTADO%20DE%20SITUACI_N%20FINANCIERA%204TO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2017",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/ESTADODESITUACI_NFINANCIERA1ER.TRIMESTRE2017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/ESTADO_DE_SITUACION_FINANCIERA_2DO_TRIMESTRE_2017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/ESTADO%20DE%20SITUACI_N%20FINANCIERA%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/ESTADO%20DE%20SITUACI_N%20FINANCIERA%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2016",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/Estado%20de%20situaci_n%20financiera%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/Estado%20de%20situaci_n%20financiera%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/Estado%20de%20Situaci_n%20financiera%203er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_1_ESTADO_DE_SITUACION_FINANCIERA/Estado%20de%20situaci_n%20financiera%204to.%20trimestre%202016.pdf"
                    }
                ]
            }
        ]
    }
];
export const estadoActividades = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.2_ESTADO_DE_ACTIVIDADES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.2_ESTADO_DE_ACTIVIDADES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.2_ESTADO_DE_ACTIVIDADES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.2_ESTADO_DE_ACTIVIDADES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.2_ESTADO_ DE_ACTIVIDADES.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.2_ESTADO_DE_ACTIVIDADES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/1_INFORMACION_CONTABLE/1.2_ESTADO_DE_ACTIVIDADES.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.2_ESTADO_DE_ACTIVIDADES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.2_ESTADO_DE_ACTIVIDADES.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2022",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2021",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2020",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2019",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/ESTADO%20DE%20ACTIVIDADES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.2_ESTADO_DE_ACTIVIDADES_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.2_ESTADO_DE_ACTIVIDADES_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.2_ESTADO_DE_ACTIVIDADES_2019.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2018",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_2_ESTADO_DE_ACTIVIDADES_2018/ESTADO%20DE%20ACTIVIDADES%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_2_ESTADO_DE_ACTIVIDADES_2018/ESTADO%20DE%20ACTIVIDADES%202do.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_2_ESTADO_DE_ACTIVIDADES_2018/ESTADO%20DE%20ACTIVIDADES%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_2_ESTADO_DE_ACTIVIDADES_2018/ESTADO%20DE%20ACTIVIDADES%204TO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2017",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/ESTADO%20DE%20ACTIVIDADES%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/ESTADO%20DE%20ACTIVIDADES%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/ESTADO%20DE%20ACTIVIDADES%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/ESTADO%20DE%20ACTIVIDADES%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2016",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/Estado%20de%20actividades%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/Estado%20de%20actividades%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/Estado%20de%20actividades%203er.%20trimestre%202016.pdfhttps://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_2_ESTADO_DE_ACTIVIDADES/Estado%20de%20actividades%204to.%20trimestre%202016.pdf"
                    }
                ]
            },
        ]
    }
];
export const estadoHP = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/1_INFORMACION_CONTABLE/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.3_ESTADO_DE_VARIACIÓN_EN_LA_HACIENDA_PÚBLICA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2022",
        "trimestre": [

            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2021",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio": "2020",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio": "2019",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/ESTADO%20DE%20VARIACION%20EN%20LA%20HACIENDA%20PUBLICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio": "2018",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA_2018/ESTADO%20DE%20VARIACI_N%20EN%20LA%20HACIENDA%20P_BLICA%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA_2018/ESTADO%20DE%20VARIACION%20EN%20LA%20HACIENDA%20P_BLICA%202do.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA_2018/ESTADO%20DE%20VARIACION%20EN%20LA%20HACIENDA%20P_BLICA%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA_2018/ESTADO%20DE%20VARIACION%20EN%20LA%20HACIENDA%20P_BLICA%204TO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2017",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/ESTADO%20DE%20VARIACI_N%20EN%20LA%20HACIENDA%20P_BLICA%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/ESTADO%20DE%20VARIACI_N%20EN%20LA%20HACIENDA%20P_BLICA%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/ESTADO%20DE%20VARIACI_N%20EN%20LA%20HACIENDA%20P_BLICA%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/ESTADO%20DE%20VARIACI_N%20EN%20LA%20HACIENDA%20P_BLICA%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio": "2016",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/Estado%20de%20variaciones%20en%20la%20hacienda%20p_blica%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/Estado%20de%20variaciones%20en%20la%20hacienda%20p_blica%202do%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/Estado%20de%20variaciones%20en%20la%20hacienda%20p_blica%203er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/Estado%20de%20variaci_nes%20en%20la%20hacienda%20p_blica%204to.%20trimestre%202016.PDF"
                    }
                ]
            },


        ]
    },
    {
        "anio": "2015",
        "trimestre": [
            {
                "trimestreNombre": "Primero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/ESTADO%20DE%20VARIACIONES%20EN%20LA%20HACIENDA%20P%C3%9ABLICA%201ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/ESTADO%20DE%20VARIACIONES%20EN%20LA%20HACIENDA%20P%C3%9ABLICA%202DO.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_3_ESTADO_DE_VARIACION_EN_LA_HACIENDA_PUBLICA/ESTADO%20DE%20VARIACIONES%20EN%20LA%20HACIENDA%20P%C3%9ABLICA%203ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },

        ]
    }
];
export const estadoSF = [
        {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.4_ESTADO_DE_CAMBIO_DE_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.4_ESTADO_DE_CAMBIO_DE_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACION%20FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.4_ESTADO_DE_CAMBIOS_DE_SITUACION_FINANCIERA_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA_2018/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%201ER.%20TRIMESTRE%202018.pdfps://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA_2018/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%202do..%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA_2018/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANCIERA_2018/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%204TO.%20TRIMESRE%202018.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI_N%20FINANCIERA%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/Estado%20de%20cambios%20de%20situaci_n%20financiera%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/Estado%20de%20cambios%20de%20situaci_n%20financiera%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/Estado%20de%20cambios%20de%20situaci_n%20financiera%203er.trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/Estado%20de%20cambios%20de%20situaci_n%20financiera%204to.%20trimestre%202016.PDF"
                    }
                ]
            }
        ]
        },
            {
                "anio" : "2015",
                "trimestre" : [
                    {
                        "trimestreNombre" : "Primero Trimestre",
                        "trimestreContenido" : [
                            {
                                "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI%C3%93N%20FINANCIERA%201ER.%20TRIMESTRE%202015.pdf"
                            }
                        ]
                    },
                    {
                        "trimestreNombre" : "Segundo Trimestre",
                        "trimestreContenido" : [
                            {
                                "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI%C3%93N%20FINANCIERA%202DO.%20TRIMESTRE%202015.pdf"
                            }
                        ]
                    },
                    {
                        "trimestreNombre" : "Tercero Trimestre",
                        "trimestreContenido" : [
                            {
                                "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_4_ESTADO_DE_CAMBIOS_EN_LA_SITUACION_FINANIERA/ESTADO%20DE%20CAMBIOS%20EN%20LA%20SITUACI%C3%93N%20FINANCIERA%203ER.%20TRIMESTRE%202015.pdf"
                            }
                        ]
                    }
            
        ]
    } 
];
export const notasEF = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.5_NOTAS_A_LOS_ESTASDOS_FINANCIEROS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.5_NOTAS_A_LOS_ESTASDOS_FINANCIEROS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.5_NOTA_A_LOS_ESTADOS_FINANCIEROS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.5_NOTA_A_LOS_ESTADOS_FINANCIEROS.doc",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.5_NOTA_A_LOS_ESTADOS_FINANCIEROS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.5_NOTA_A_LOS_ESTADOS_FINANCIEROS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.5_NOTAS_A_LOS_ESTADOS_FINANCIEROS_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS_2018/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS_2018/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS%202DO.%20%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS_2018/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS_2018/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS%204TO.%20TRIMESTRE%202018.pdf/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/NOTAS%20A%20LOS%20ESTADOS%20DE%20SITUACI_N%20FINANCIERA%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/NOTAS%20A%20LOS%20ESTADOS%20DE%20SITUACI_N%20FINANCIERA%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/NOTAS%20A%20LOS%20ESTADOS%20DE%20SITUACI_N%20FINANCIERA%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/NOTAS%20A%20LOS%20ESTADOS%20DE%20SITUACI_N%20FINANCIERA%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/Notas%20a%20los%20estados%20financieros%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/Notas%20a%20los%20estados%20financieros%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/Notas%20a%20los%20estados%20financieros%203er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/Notas%20a%20los%20estados%20financieros%204to.%20trimestre%202016.PDF"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS%201ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS%202DO.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_5_NOTAS_A_LOS_ESTADOS_FINANCIEROS/NOTAS%20A%20LOS%20ESTADOS%20FINANCIEROS%203ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            }


        ]
    }
       
];
export const estadoAA = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.6_ESTADO_ANALITICO_DEL_ACTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.6_ESTADO_ANALITICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.6_ESTADO_ANALITICO_DEL_ACTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.6_ESTADO_ANALITICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.6_ESTADO_ANALITICO_DEL_ACTIVO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.6_ESTADO_ANALITICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.6_ESTADO_ANALITICO_DEL_ACTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.6_ESTADO_ANALITICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.6_ESTADO_ANALITICO_DEL_ACTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.6_ESTADO_ANALITICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.6_ESTADO_ANALITICO_ACTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/1.6_ESTADO_ANALITICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.6_ESTADO_ANALITICO_DEL_ACTIVO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.6_ESTADO_ANALITICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.6_ESTADO_ANALITICO_DEL_ACTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.6_ESTADO_ANALÍTICO_DEL_ACTIVO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/ANALITICO%20DEL%20ACTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.6_ESTADO_ANALITICO_DEL_ACTIVO_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.6_ESTADO_ANALITICO_DEL_ACTIVO_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.6_ESTADO_ANALITICO_DEL_ACTIVO_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_6_ESTADO_ANALITICO_DEL_ACTIVO_2018/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_6_ESTADO_ANALITICO_DEL_ACTIVO_2018/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%202do.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_6_ESTADO_ANALITICO_DEL_ACTIVO_2018/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_6_ESTADO_ANALITICO_DEL_ACTIVO_2018/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%204TO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/ESTADO%20ANAL_TICO%20DEL%20ACTIVO%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/Estado%20anal_tico%20del%20activo%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/Estado%20anal_tico%20del%20activo%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/Estado%20Anal_tico%20del%20activo%203er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/Estado%20anal_tico%20del%20activo%204to.%20trimestre%202016.PDF"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/ESTADO%20ANAL%C3%8DTICO%20DEL%20ACTIVO%201ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/ESTADO%20ANAL%C3%8DTICO%20DEL%20ACTIVO%202DO.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_6_ESTADO_ANALITICO_DEL_ACTIVO/ESTADO%20ANAL%C3%8DTICO%20DEL%20ACTIVO%203ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            }


        ]
    }
        
];
export const estadoFE = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.7_ESTADO_DE_FLUJO_EFECTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.7_ESTADO_DE_FLUJO_EFECTIVO.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.7_ESTADO_DE_FLUJO_EFECTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.7_ESTADO_DE_FLUJOS_DE_EFECTIVO_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO_2018/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO_2018/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%202DO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO_2018/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO_2018/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%204TO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/Estado%20de%20flujos%20de%20efectivo%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/Estado%20de%20flujos%20de%20efectivo%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/Estado%20de%20flujos%20de%20efectivo%203er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/Estado%20de%20flujos%20de%20efectivo%204to.%20trimestre%202016.PDF"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%201ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%202DO.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_7_ESTADO_DE_FLUJOS_DE_EFECTIVO/ESTADO%20DE%20FLUJOS%20DE%20EFECTIVO%203ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            


        ]
    }
        
];
export const estadoADOP = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.8_ESTADO_ANALITICO_DE_LA_DUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.8_ESTADO_ANALITICO_DE_LA_DUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/1_INFORMACION_CONTABLE/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_MZO_2019/ESTADO%20ANALITICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS_2018/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS_2018/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%202do.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS_2018/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS_2018/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%204TO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/ESTADO%20ANAL_TICO%20DE%20LA%20DEUDA%20Y%20OROS%20PASIVOS%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/Estado%20anal_tico%20de%20la%20%20deuda%20y%20otros%20pasivos%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/Estado%20anal_tico%20de%20la%20%20deuda%20y%20otros%20pasivos%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/Estado%20anal_tico%20de%20la%20deuda%20y%20otros%20pasivos%203er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/Estado%20anal_tico%20de%20la%20deuda%20y%20otros%20pasivos%204to.%20trimestre%202016.PDF"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/ESTADO%20ANAL%C3%8DTICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%201ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/ESTADO%20ANAL%C3%8DTICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%202DO.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_8_ESTADO_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS/ESTADO%20ANAL%C3%8DTICO%20DE%20LA%20DEUDA%20Y%20OTROS%20PASIVOS%203ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            }


        ]
    }
        
];
export const informePC = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.9_INFORME_DE_PASIVOS_CONTINGENTES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.9_INFORME_DE_PASIVOS_CONTINGENTES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.9_INFORME_DE_PASIVOS_CONTINGENTES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.9_INFORME_DE_PASIVOS_CONTINGENTES.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.9_INFORME_PASIVOS_CONTINGENTES.docx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.9_INFORME_PASIVOS_CONTINGENTES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.9_INFORME_DE_PASIVOS_CONTINGENTES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.9_INFORME_DE_PASIVOS_CONTINGENTES.docx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.9_INFORME_DE_PASIVOS_CONTINGENTES.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_9_INFORME_DE_PASIVOS_CONTINGENTES_2018/INFORME%20DE%20PASIVOS%20CONTINGENTES%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.9_INFORME_DE_PASIVOS_CONTINGENTES_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.9_INFORME_DE_PASIVOS_CONTINGENTES_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.9_INFORME_DE_PASIVOS_CONTINGENTES_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_9_INFORME_DE_PASIVOS_CONTINGENTES_2018/INFORME%20DE%20PASIVOS%20CONTINGENTES%201ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_9_INFORME_DE_PASIVOS_CONTINGENTES_2018/INFORME%20DE%20PASIVOS%20CONTINGENTES%202do.%20TRIMESTRE%202018.pdfhttps://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_9_INFORME_DE_PASIVOS_CONTINGENTES_2018/INFORME%20DE%20PASIVOS%20CONTINGENTES%203ER.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2018/1_INFORMACION_CONTABLE_2018/1_9_INFORME_DE_PASIVOS_CONTINGENTES_2018/INFORME%20DE%20PASIVOS%20CONTINGENTES%204TO.%20TRIMESTRE%202018.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/INFORME%20DE%20PASIVOS%20CONTINGENTES%201ER.%20TRIMESTRE%202017.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/INFORME%20DE%20PASIVOS%20CONTINGENTES%202DO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/INFORME%20DE%20PASIVOS%20CONTINGENTES%203ER.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2017/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/INFORMES%20DE%20PASIVOS%20CONTINGENTES%204TO.%20TRIMESTRE%202017.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/Informe%20de%20pasivos%20contingentes%201er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/Informe%20de%20pasivos%20contingentes%202do.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/Informe%20de%20pasivos%20contingentes%203er.%20trimestre%202016.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2016/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/Informe%20de%20pasivos%20contingentes%204to.%20trimestre%202016.PDF"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/INFORME%20DE%20PASIVOS%20CONTINGENTES%201ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/INFORME%20DE%20PASIVOS%20CONTINGENTES%202DO.%20TRIMESTRES%202015.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2015/1_INFORMACION_CONTABLE/1_9_INFORME_DE_PASIVOS_CONTINGENTES/INFORME%20DE%20PASIVOS%20CONTINGENTES%203ER.%20TRIMESTRE%202015.pdf"
                    }
                ]
            }


        ]
    }
        
];
export const conciliacionPC = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/1_INFORMACION_CONTABLE/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/1_INFORMACION_CONTABLE/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/1-REPORTE-CONTABLES/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/1.10_CONCILIACIÓN_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADO_FINANCIEROS_ENE_JUN_2019/1_INFORMACION_CONTABLE_2019/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2019/ESTADOS_FINANCIEROS_ENERO_SEPT_2019/1_INFORMACION_CONTABLE_2019/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/ESTADOS_FINANCIEROS_ENERO_DIC_2019/1_INFORMACION_CONTABLE_2019/1.10_CONCILIACION_ENTRE_LOS_INGRESOS_PRESUPUESTARIOS_Y_CONTABLES_2019.pdf"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const conciliacionPC2 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            }, {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.11_CONCILIACION_ENTRE_LOS EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.11_CONCILIACION_ENTRE_LOS EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.pdf"
                    }
                ]
            },
         ]
    },   
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.11_CONCILIACION_ENTRE_LOS EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.11_CONCILIACION_ENTRE_LOS EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/1.11_CONCILIACION_ENTRE_LOS EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/2do/1_INFORMACION_CONTABLE/1.11_CONCILIACION_ENTRE_LOS_EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.pdf"
                    }
                ]
            }, {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/1_INFORMACION_CONTABLE/1.11_CONCILIACIÓN_ENTRE_LOS_EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/1_INFORMACION_CONTABLE/1.11_CONCILIACIÓN_ENTRE_LOS_EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/4TO_2023/1.11_CONCILIACION_ENTRE_LOS EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/4to/1_INFORMACION_CONTABLE/1.11_CONCILIACION_ENTRE_LOS_ EGRESOS_PRESUPUESTARIOS_Y_LOS_GASTOS_CONTABLES.pdf"
                    }
                ]
            },
         ]
    }
            
];
export const conciliacionPC3 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/1er_2024/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/1er_2024/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/2do_2024/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/2do_2024/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/3er_2024/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/3er_2024/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/1_I_C/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
         ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/1_INFORMACION_CONTABLE/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/2do/1_INFORMACION_CONTABLE/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/3ER_2023/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/1_INFORMACION_CONTABLE/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/4TO_2023/1.12_ESTADO_ANALITICO_DEL_PASIVO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/4to/1_INFORMACION_CONTABLE/1.12_ESTADO_ANALITICO_DEL_PASIVO.pdf"
                    }
                ]
            },
         ]
    },
    
            
];
export const estado_2_1 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.1_ESTADO_ANALITICO_DE_INGRESOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.1_ESTADO_ANALITICO_DE_INGRESOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.1_ESTADO_ANALITICO_DE_INGRESOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.1_ESTADO_ANALITICO_DE_INGRESOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.1_ESTADO_ANALITICO_DE_INGRESOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.1_ESTADO_ANALITICO_DE_INGRESOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.1_ESTADO_ANALITICO_DE_INGRESOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.1_ESTADO_ANALITICO_DE_INGRESOS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.1_ESTADO_ ANALITICO_DE_INGRESOS.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.1_ESTADO_ANALITICO_DE_LOS_INGRESOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.1_ESTADO_ANALITICO_DE_LOS_INGRESOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.1_ESTADO_ANALITICO_DE_LOS_INGRESOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.1_ESTADO_ANALITICO_DE_LOS_INGRESOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.1_ESTADO_ANALITICO_DE_LOS_INGRESOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.1_ESTADO_ANALITICO_DE_INGRESOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.1_ESTADO_ANALITICO_DE_INGRESOS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
       
];
export const estado_2_2 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.2_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.2_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.2_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.2_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.2_ESTADO_ ANALÍTICO_DEL_ EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_OBJETO_DEL_GASTO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.2_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACIÓN_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const estado_2_3 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.3_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.3_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.3_ESTADO_ANALÍTICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE-EGRESOS-CLASIFICACION_ECONOMICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.3_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ECONOMICA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const estado_2_4 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL_EJERCICIO-DEL_PRESUPUESTO-DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL_EJERCICIO-DEL_PRESUPUESTO-DE_EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.4_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.4_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.4_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.4_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.4_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.4_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.4_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.4_ESTADO_ANALITICO_DEL_EJERCICIO-DEL_PRESUPUESTO-DE_EGRESOS_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.4_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE EGRESOS_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const estado_2_5 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACIÓN_ADMINISTRATIVA.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.5_ESTADO_ANALITICO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.5_ESTADO_ANALITICO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO-DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.5_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }

        
];
export const endeuda_2_6 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.6_ENDEUDAMIENTO_NETO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.6_ENDEUDAMIENTO_NETO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
       
];
export const interes_2_7 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.7_INTERES_DE_LA_DEUDA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.7_INTERES_DE_LA_DEUDA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.7_INTERES_DE_LA_DEUDA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.7_INTERES_DE_LA_DEUDA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.7_INTERESES DE LA DEUDA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.7_INTERES_DE_LA_DEUDA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.7_INTERES_DE_LA_DEUDA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.7_INTERES_DE_LA_DEUDA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.7_INTERES_DE_LA_DEUDA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const indicador_2_8 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2-INFORMACION-PRESUPUESTAL/2.8_INDICADORES_DE_POSTURA_FISCAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/2_INFORMACION_PRESUPUESTAL/2.8_INDICADORES_DE_POSTURA_FISCAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/2_INFORMACION_PRESUPUESTAL/2.8_INDICADORES_DE_POSTURA_FISCAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/2_I_P/2.8_INDICADORES_DE_POSTURA_FISCAL.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2-INFORMACION-PRESUPUESTAL/2.8_INDICADORES DE POSTURA FISCAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/2.8_INDICADORES_DE_POSTURA_FISCAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/2.8_INDICADORES_DE_POSTURA_FISCAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/2.8_INDICADORES_DE_POSTURA_FISCAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/2.8_INDICADORES_DE_POSTURA_FISCAL.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const indicador_2_9 = [
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/2.9/1ER/2.9_ESTADO_ANALITICO_DE_INGRESOS_PRESUPUESTALES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/2_INFORMACION_PRESUPUESTAL/2.9_ESTADO_ANALITICO_DE_INGRESOS_ PRESUPUESTALES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/2.9/2DO/2.9_ESTADO_ANALITICO_DE_INGRESOS_PRESUPUESTALES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/2do/2_INFORMACION_PRESUPUESTAL/2.9_ESTADO_ANALITICO_DE_ INGRESOS_PRESUPUESTALES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/2.9/3ER/2.9_ESTADO_ANALITICO_DE_INGRESOS_PRESUPUESTALES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/2_INFORMACION_PRESUPUESTAL/2.9_ESTADO_ANALITICO_DE_INGRESOS_PRESUPUESTALES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/2.9/4TO/2.9_ESTADO_ANALITICO_DE_INGRESOS_PRESUPUESTALES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/4to/2_INFORMACION_PRESUPUESTAL/2.9_ESTADO_ANALITICO_DE_INGRESOS_PRESUPUESTALES.pdf"
                    }
                ]
            },
        ]
    },
        
];
export const gasto_3_1 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3-INFORMACION-PROGRAMATICA/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3-INFORMACION-PROGRAMATICA/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/3_I_P/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/3_I_P/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/3_INFORMACION_PROGRAMATICA/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3-INFORMACION/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/3.1_GASTO_POR_CATEGORIA_PROGRAMATICA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
       
];
export const programas_3_2 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3-INFORMACION-PROGRAMATICA/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSIÓN.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3-INFORMACION-PROGRAMATICA/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSIÓN.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSIÓN.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSIÓN.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSIÓN.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/3_I_P/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSIÓN.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/3_I_P/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSIÓN.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/3_INFORMACION_PROGRAMATICA/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.xlsx",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3-INFORMACION/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/3.2_PROGRAMAS_Y_PROYECTOS_DE_INVERSION.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
       
];
export const indicadores_3_3 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/3.3_MATRIZ_DE_INDICADORES_PARA_RESULTADOS.rar",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/3.3_MATRIZ_DE_INDICADORES_PARA_RESULTADOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.3_INDICADORES_DE_RESULTADOS.rar",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.3_INDICADORES_DE_RESULTADOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.3 MATRIZ_DE_INDICADORES_PARA_RESULTADOS.rar",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.3 MATRIZ_DE_INDICADORES_PARA_RESULTADOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/3_I_P/3.3_MATRIZ_DE_INDICADORES_PARA_RESULTADOS..pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/4to/3_INFORMACION_PROGRAMATICA/3.3_INDICADORES_DE_RESULTADOS.rar",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3-INFORMACION/3.3_INDICADORES_DE_RESUTADOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/3.3_ INIDCADORES_DE_RESULTADOS.rar",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/3.3_INDICADORES_DE_RESULTADOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/3.3_INDICADORES_DE_RESULTADOS.rar",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/3ER_2023/3.3_INDICADORES_DE_RESULTADOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/3.3_INDICADORES_DE_RESULTADOS.rar"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const evaluacion_3_4 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/3.4_EVALUACION_DE_DESEMPEÑO_DE_PROGRAMAS_Y_POLITICAS_PUBLICAS.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.4_EVALUACION_DE_DESEMPEÑO_DE_PROGRAMAS_Y_POLITICAS_PUBLICAS.rar",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.4_EVALUACION_DE_DESEMPEÑO_DE_PROGRAMAS_Y_POLITICAS_PUBLICAS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.4 EVALUACION_DE_DESEMPEÑO_DE_PROGRAMAS_Y _POLITICAS_PUBLICAS.rar",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.4 EVALUACION_DE_DESEMPEÑO_DE_PROGRAMAS_Y _POLITICAS_PUBLICAS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/3_I_P/3.4_VALUACION_DE_DESEMPEÑO_DE_PROGRAMAS_Y _POLITICAS_PUBLICAS.rar"
                    }
                ]
            }
        ]
    },
   
];
export const metodo_3_5 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/3.5_METODOLOGIAS_E_INDICADORES_DE_DESEMPEÑO.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.5_METODOLOGIAS_E_INDICADORES_DE_DESEMPEÑO.rar",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.5_METODOLOGIAS_E_INDICADORES_DE_DESEMPEÑO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.5 Metodologías e Indicadores de Desempeño.rar",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.5_METODOLOGIAS_E_INDICADORES_DE_DESEMPEÑO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/3_I_P/3.5_METODOLOGIAS_E_INDICADORES_DE_DESEMPEÑO.rar"
                    }
                ]
            }
        ]
    }
];
export const programas_3_6 = [
    {
        "anio": "2025",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2025/Programa_Anual_de_Evaluación_1er_trim_2025.PDF"
                    }
                ]
            },/*
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.6_PROGRAMA_ANUAL_DE_EVALUACIONES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/Programa_Anual_de_Evaluacion_4_trimestre_2024.PDF"
                    }
                ]
            }*/
        ]
    },
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/3.6_PROGRAMA_ANUAL_DE_EVALUACION.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/3_INFORMACION_PROGRAMATICA/3.6_PROGRAMA_ANUAL_DE_EVALUACIONES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/3_INFORMACION_PROGRAMATICA/3.6_PROGRAMA_ANUAL_DE_EVALUACIONES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/Programa_Anual_de_Evaluacion_4_trimestre_2024.PDF"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/Programa_Anual_de_Evaluacion_2022_SITMAH_4to_trimestre_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/Programa_Anual_de_Mejora_Regulatoria_2024_del_SITMAH.pdf"
                    }
                ]
            }
        ]
    }
       
];
export const programas_3_7 = [
    {
        "anio": "PRIMER TRIMESTRE 2025",
        "trimestre": [
            {
         
                "trimestreNombre": "2023_SITMAH_ASM01",
                "trimestreContenido": [
                    {
                        
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2025/2023_SITMAH_ASM01.PDF"
                    
                    }
                ]
            },
            {
                "trimestreNombre": " 2023_SITMAH_ASM02",
                "trimestreContenido": [
                    {
                        "excel": "",
                      "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2025/2023_SITMAH_ASM02.pdf"
                   
                    }
                ]
            },
            {
                "trimestreNombre": "2023_SITMAH_ASM03",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2025/2023_SITMAH_ASM03.pdf"
                    }
                ]
            },/*
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/Programa_Anual_de_Evaluacion_4_trimestre_2024.PDF"
                    }
                ]
            }*/
        ]
    },
    
       
];
export const muebles_4_1 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/4-INVENTARIO-DE-BIENES/4.1_MUEBLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/4-INVENTARIO-DE-BIENES/4.1_MUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/4_INVENTARIO_DE_BIENES/4.1_MUEBLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/4_INVENTARIO_DE_BIENES/4.1_MUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/4_INVENTARIO_DE_BIENES/4.1_MUEBLES.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/4_INVENTARIO_DE_BIENES/4.1_ INMUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/4_I_B/4.1_MUEBLES.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/4_I_B/4.1_MUEBLES.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-INVENTARIO/4.1_MUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/4.1_MUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/4.1_MUEBLES.zip",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/4.1_MUEBLES.rar"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
       
];
export const inmuebles_4_2 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-INVENTARIO/4.2_INMUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/4_INVENTARIO_DE_BIENES/4.2_INMUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/4_INVENTARIO_DE_BIENES/4.2_INMUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/4_I_B/4.2_INMUEBLES.pdf"
                    }
                ]
            }
        ]
    },
    
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/4.2_INMUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/4.2_INMUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/3ER_2023/4.2_INMUEBLES.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/4TO_2023/4.2_INMUEBLES.pdf"
                    }
                ]
            },
        ]
    }        
];
export const cuenta_p_5_1 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/5.1_CUENTA_PUBLICA_2023.rar",
                        "pdf": ""
                    }
                ]
            },
            /*{
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/5_CUENTA PUBLICA/5.1_CUENTA_PUBLICA_2023.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/5_CUENTA_PUBLICA/5.1_CUENTA_PUBLICA_2023.rar",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/5._CUENTA_PUBLICA.rar"
                    }
                ]
            },*/
        ]
    },    
    {
        "anio": "2023",
        "trimestre": [
            /*{
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                       // "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/5._CUENTA_PUBLICA.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                       // "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2022/IGF_1ER_TRIMESTRE_2023.rar",
                        "pdf": ""
                    }
                ]
            },*/
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                       // "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/5._CUENTA_PUBLICA.rar",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/CUENTA_2023.pdf"
                    }
                ]
            },
            /*{
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/5._CUENTA_PUBLICA.rar"
                    }
                ]
            },*/
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                       // "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/CUENTA_PUBLICA_2022.rar",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/CUENTA_2022.pdf"
                    }
                ]
            },
            /*{
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },*/
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/CUENTA_PUBLICA_2021.rar",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/CUENTA_2021.pdf"
                    }
                ]
            },
            /*{
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },*/


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                       // "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/CUENTA_PUBLICA_2020.rar",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/CUENTA_2020.pdf"
                    }
                ]
            },
            /*{
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },*/


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                       // "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/CUENTA_PUBLICA_2019.rar",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1ER_CUENTA_2019.pdf"
                    }
                ]
            },
            /*{
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },*/


        ]
    },
    /*{
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }*/
       
];
export const dictamen_5_2 = [
    {
        "anio": "2025",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        /*"excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/5-CUENTA-PUBLICA/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",*/
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2025/5.2_DICTAMEN_ DE_AUDITORIA_EXTERNA_2024.pdf"
                    }
                ]
            }/*,
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/5_CUENTA PUBLICA/5.2_DICTAMEN_DE_AUDITORIA_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/5_CUENTA_PUBLICA/5.2_DICTAMEN_DE_AUDITORIA_FINANCIERA.pdf"
                    }
                ]
            }*/
        ]
    },
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        /*"excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/5-CUENTA-PUBLICA/1.1_ESTADO_DE_SITUACION_FINANCIERA.xlsx",*/
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/5-CUENTA-PUBLICA/5.2_DICTAMEN_DE_AUDITORIA_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/5_CUENTA PUBLICA/5.2_DICTAMEN_DE_AUDITORIA_FINANCIERA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Tercer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/5_CUENTA_PUBLICA/5.2_DICTAMEN_DE_AUDITORIA_FINANCIERA.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/ANUAL/5.2_DICTAMEN_DE_AUDITORIA_FINANCIERA.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2022",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/5.2_DICTAMEN_DE_AUDITORIA_EXTERNA_2022.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2021",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/5.2_DICTAMEN_DE_AUDITORIA_EXTERNA_2021.pdf"
                    }
                ]
            }
        ]
    }
];
export const aseh_5_3 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/5-CUENTA-PUBLICA/5.3_INFORMES_DE_GESTION_FINANCIERA_2024.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/5_CUENTA PUBLICA/5.3_INFORMES_DE_GESTIÓN_FINANCIERA_2024.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/5_CUENTA_PUBLICA/5.3_INFORMES_DE GESTION_FINANCIERA_2024.rar",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/5_C_P/5.3_INFORMES_DE_GESTION_FINANCIERA.rar",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/5.3_ASEH_INFORMES_DE_GESTION_FINANCIERA.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/5.3 ASEH INFORMES GESTIÓN FINANCIERA.rar",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/5.3_ASEH_INFORMES_DE_GESTION_FINANCIERA.rar",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/5.3_ASEH_INFORMES_DE_GESTION_FINANCIERA.rar"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
    
        
];
export const trimes_6_1 = [
    {
        "anio": "",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            }
        ]
    },
    
    
];
export const anual_6_2 = [
    {
        "anio": "",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            }
        ]
    },
    
    
];
export const pae_7_1 = [
    {
        "anio": "2019",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const resultado_7_2 = [
    {
        "anio": "2018",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            }
        ]
    },
    {
        "anio": "2017",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            }
        ]
    },
];
export const montos_7_3 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/7.3_MONTOS_PAGADOS_POR_AYUDAS_Y_SUBSIDIOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/7_OTRAS OBLIGACIONES L.G.C.G/7.3_MONTOS_PAGADOS_POR_AYUDAS_Y_SUBSIDIOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/7_OTRAS OBLIGACIONES L.G.C.G/7.3_MONTOS_PAGADOS_POR_AYUDAS_Y_SUBSIDIOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/7_OTRAS_OBLIGACIONES_L.G.C.G/7.3_MONTOS_PAGADOS_POR_AYUDAS_Y_SUBSIDIOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/7_OTRAS_OBLIGACIONES_L.G.C.G/7.3_MONTOS_PAGADOS_POR_AYUDAS_Y_SUBSIDIOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/7_L_G_C_G/7.3_MONTOS_PAGADOS_POR_AYUDAS_Y_SUBSIDIOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/7_L_G_C_G/7.3_MONTOS_PAGADOS_POR_AYUDAS_Y_SUBSIDIOS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
        
];
export const programas_8 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/8_PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/8_PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/8_PROGRAMA ANUAL DE ADQUISICIONES/8_PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/8_PROGRAMA ANUAL DE ADQUISICIONES/8_PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/8_LEY_DE_ADQUISICIONES/8_PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/8_LEY_DE_ADQUISICIONES/8_PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.pdf"
                    }
                ]
            }/*,
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/8._PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.pdf"
                    }
                ]
            },*/
        ]
    },
    {
        "anio": "2022",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/8_PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.xls",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/8._PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/8._PROGRAMA_ANUAL_DE_ADQUISICIONES_ARRENDAMIENTOS_Y_SERVICIOS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
        
];
export const n_9_1 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xls",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/3ER_2023/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xls",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/4TO_2023/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xlsx",
                        "pdf": ""
                    }
                ]
            },
     ]
    },
    {
        "anio": "2022",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.1_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
        
];
export const n_9_2 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xls",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Tercero Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/3ER_2023/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xls",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Cuarto Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/4TO_2023/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": ""
                    }
                ]
            }
        ]
    },
    {
        "anio": "2022",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.2_INFORME_ANALITICO_DE_LA_DEUDA_Y_OTROS_PASIVOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.2_INFORME_ANALITICO_DE_LA _DEUDA_Y_OTROS_PASIVOS.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    }
       
];
export const n_9_3 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DE_DIFERENTES_FINANCIAMIENTOS.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DE_DIFERENTES_FINANCIAMIENTOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DE_DIFERENTES_FINANCIAMIENTOS.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DE_DIFERENTES_FINANCIAMIENTOS.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DE_DIFERENTES_FINANCIAMIENTOS.xlsx",
                        "pdf" : ""
                    }
                ]
            },        
        ]
    },
    {
        "anio" : "2023",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/2DO_2023/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS.xls",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/3ER_2023/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS.xls",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/4TO_2023/9.3_INFORME_ANALITICO_DE_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS.xlsx",
                        "pdf" : ""
                    }
                ]
            },
        ]
    }

];               
export const n_9_4 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.4_BALANCE_PRESUPUESTARIO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.4_BALANCE_PRESUPUESTARIO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.4_BALANCE_PRESUPUESTARIO.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.4_BALANCE_PRESUPUESTARIO.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.4_BALANCE_PRESUPUESTARIO.xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.4_BALANCE_PRESUPUESSTARIO.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.4_BALANCE_PRESUPUESTARIO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.4_BALANCE_PRESUPUESTARIO.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.4_BALANCE_PRESUPUESTARIO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.4_BALANCE_PRESUPUESTARIO.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.4_BALANCE_PRESUPUESTARIO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.4_BALANCE_PRESUPUESTARIO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.4_BALANCE_PRESUPUESTARIO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
       
];
export const n_9_5 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.5._ESTADO_ANALITICOO_DE_INGRESOS_DETALLADO.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.5_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
        
];
export const n_9_6 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.6_EA.E.P.E._DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.6_EA.E.P.E._DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/1ro/9_LEY_DE_DISCIPLINA_FINANCIERA/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO_DEL_GASTO.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.6_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_POR_OBJETO-DEL_GASTO.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
       
];
export const n_9_7 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.7_E.A.E.P.E._DETALLADO_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.7_E.A.E.P.E._DETALLADO_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE EGRESOS_DETALLADO_CLASIFICACIÓN_ADMINISTRATIVA.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.7_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE EGRESOS_DETALLADO_CLASIFICACION_ADMINISTRATIVA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
        
];
export const n_9_8 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.8_E.A.E.P.E._DETALLADO_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.8_E.A.E.P.E._DETALLADO_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.8_ESTADO_ANALITICO_DEL EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/ACP_2023/3ro/9_LEY_DE_DISCIPLINA_FINANCIERA/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.xls",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.8_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_FUNCIONAL.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
        
];
export const n_9_9 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/9_LEY_DE_DISCIPLINA_FINANCIERA/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/2_TRIMESTRE_2024/9_LEY DE DISCIPLINA FINNACIERA/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIF._DE_SERV._PERS._POR_CAT..xlsx",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/3ER_T_2024/9_LEY_DE_DISCIPLINA_FINNACIERA/9.9_E.A.E.P.E._DETALLADO_CLASIF._DE_SERV._PERS._POR_CAT..xlsx",
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/4to_2024/9_L_D_F/9.9_E.A.E.P.E._DETALLADO_CLASIF._DE_SERV._PERS._POR_CAT..xlsx",
                        "pdf": ""
                    }
                ]
            },
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/1ER_2023/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_C.S.P.C..xls",
                        "pdf": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/9-LEY-FINANCIERA/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre": "Segundo Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.xls",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/2T/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/xlsx/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.xlsx",
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/4-T-2023/pdf/9.9_ESTADO_ANALITICO_DEL_EJERCICIO_DEL_PRESUPUESTO_DE_EGRESOS_DETALLADO_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA.pdf"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
       
];
export const n_9_10 = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "ANUAL",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.10 BALANCE PRESUPUESTARIO DE RECURSOS DISPONIBLES NEGATIVOS.xlsx"
                    }
                ]
            }

        ]
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "ANUAL",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.10_BALANCE PRESUPUESTARIO_DE_RECURSOS_DISPONIBLES_NEGATIVO_2023.PDF"
                    }
                ]
            }

        ]
    }
       
];
export const n_9_11 = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "ANUAL",
                "trimestreContenido" : [
                    {
                       "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.11_AUMENTO O CREACIÓN DEL GASTO DEL PRESUPUESTO DE EGRESOS.xlsx"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "ANUAL",
                "trimestreContenido" : [
                    {
                       "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.11_AUMENTO_O_CREACION_DEL_PRESUPUESTO_DE_EGRESOS_DURANTE_EL_EJERCICIO_2023.PDF"
                    }
                ]
            },
        ]
    }
];
export const n_9_12 = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "ANUAL",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.12 DAIPCCE.xlsx"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "ANUAL",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.12_DEVENGADOS_EN_EL_AÑO_QUE_INTEGRA_EL_PASIVO_CIRCULANTE_AL_CIERRE_DEL_EJERCICIO_2023.PDF"
                    }
                ]
            },
        ]
    }        
];
export const n_9_13 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.13 IEDILD.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.13_INGRESOS_EXCEDENTES_DERIVADOS_DE_INGRESOS_DE_LIBRE_DISPOSICION_2023.PDF"
                    }
                ]
            }
        ]
    },
        
];
export const n_9_14 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.14 CDPyO.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.14_CONTRATACION_DE_DEUDA_PUBLICA_Y_OBLIGACIONES_A_CORTO_PLAZO.PDF"
                    }
                ]
            }
        ]
    },
        
];
export const n_9_15 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.15 IDCOCP.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.15_INFORMACION_DETALLADA_DE_CONTRATACION_DE_OBLIGACIONES_A_CORTO_PLAZO.PDF"
                    }
                ]
            }
        ]
    },
];
export const n_9_16 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.16_RESULTADOS-DE_INGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.16_RESULTADOS-DE_INGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
];
export const n_9_17 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.17_RESULTADOS_DE_EGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.17_RESULTADOS_DE_EGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
];
export const n_9_18 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.18_PROYECCIONES_DE_INGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.18_PROYECCIONES_DE_INGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
];
export const n_9_19 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.19_PROYECCIONES_DE_EGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.19_PROYECCIONES_DE_EGRESOS.xlsx"
                    }
                ]
            }
        ]
    },
];
export const n_9_20 = [
    {
        "anio": "2024",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2024/4to_2024/ANUAL_2024/9.20_INFORME_SOBRE_ESTUDIOS_ACTUARIALES_LDF.xlsx"
                    }
                ]
            }
        ]
    },
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "excel": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.20_INFORME_SOBRE_ESTUDIOS_ACTUARIALES_LDF.xlsx"
                    }
                ]
            }
        ]
    },
];
export const n_9_21 = [
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "ANUAL",
                "trimestreContenido": [
                    {
                        "pdf": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/AC_2023/ANUAL/9.21_GUIA_DE_CUMPLIMIENTO.pdf"
                    }
                ]
            }
        ]
    },
];
export const n_9_22 = [
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const n_9_23 = [
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const n_9_24 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const INICIATIVA_DE_LEY_INGRESOS = [
    
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
   
];
export const INFORMACION_COMPLEMENTARIA = [
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const LEY_INGRESOS = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const LEY_ENTIDADES = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const LEY_CONTABILIDAD = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const MANUAL_CONTABILIDAD = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/Manual_contabilidad.pdf",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/Manual_contabilidad.pdf"
                    }
                ]
            },/*
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },*/


        ]
    },
];
export const NORMA_1 = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_2 = [
    {
        "anio": "2023",
        "trimestre": [
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre": "Primer Trimestre",
                "trimestreContenido": [
                    {
                        "excel": "",
                        "pdf": ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/3T/MONTOS.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "",
                        "pdf" : ""
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

       
];
export const NORMA_3 = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_4 = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_5 = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_6 = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_7 = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_8 = [
    
    {
        "anio" : "2022",
        "trimestre" : [
            
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
        ]
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
        
];
export const NORMA_9 = [
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_10 = [
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_11 = [
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_12 = [
    {
        "anio" : "",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_13 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_14 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },


];
export const NORMA_15 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_16 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_17 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_18 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_19 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_20 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_21 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_22 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const bueno = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_24 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_25 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_26 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_27 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_28 = [
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },

];
export const NORMA_29 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_30 = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];
export const NORMA_31 = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    
];
export const NORMA_32 = [
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/"
                    }
                ]
            },


        ]
    },
];

